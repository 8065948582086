.Seller-logo {
  height: 10vmin;
}

.Seller-logo-large {
  height: 18vmin;
  padding: 1em;
}

.Tool-logo {
  height: 5vmin;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}

.short-logo {
  height: 15px;
  margin-right: 8px;
}

.header-bar {
  background-color: #2861E2;
  height: 64px;
}

.footer-bar {
  background-color: navy;
  color: white;
  padding: 1em;
  justify-content: space-between;
}

.footer-logo {
  width: 8em;
}

.admin-page-container {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}

@media (max-width: 900px) {
  .admin-page-container {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

.super-admin-page-container {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}

@media (max-width: 900px) {
  .super-admin-page-container {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
